import React from 'react';
import { Container } from 'react-bootstrap';
import aboutImg from '../../assets/images/image 1.png'
import './section.css'

const SectionFoure = () => {
    return (
        <div className='bgColor' id="about">
            <Container>
                <div className="row">
                    <div className="col-md-5">
                        <div className="text-white headingFoure">
                            <h1> <span className='mainColor'>About</span> Us</h1>
                            <p>Embark on a journey of discovery as you explore the captivating
                                destinations and breathtaking landscapes that our world has to
                                offer. Whether you seek the thrill of adventure, the serenity of
                                nature, or the charm of cultural experiences, our travel guides
                                are here to inspire and assist you in planning your perfect
                                getaway.</p>

                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <img src={aboutImg} alt="abutImage" className='responsiveImg' />
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default SectionFoure