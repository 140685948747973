
import React from 'react'
import logo from '../../../assets/images/siteLogo/siteLogo.png'
import { Container } from 'react-bootstrap';
import './footer.css'
import { Facebook, Instagram, Twitter } from 'react-bootstrap-icons';
const Footer = () => {
    return (
        <div className='bgDark p-5'>
            <Container>
                <footer className="text-center text-lg-start text-white">
                    <img src={logo} alt="logo" width={100} />
                    <div className="row mt-4">
                        <div className="col-lg-3 col-md-6">
                            <div className="text-white">
                                <h3>LET'S WORK TOGETHER</h3>
                                <p className='mt-4' style={{ fontSize: '12px' }}>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolores, ab.</p>
                                <div className="text-white">
                                    <Instagram className='m-3 fs-4' />
                                    <Facebook className='m-2 fs-4' />
                                    <Twitter className='m-1 fs-4' />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <ul className='footerLinks'>
                                <li className="list-group-item"><span className='mainColor'>Company</span></li>
                                <li className="list-group-item">Home</li>
                                <li className="list-group-item">About Us</li>
                                <li className="list-group-item">Contact Us</li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <ul className='footerLinks'>
                                <li className="list-group-item"><span className='mainColor'>Other Menu</span></li>
                                <li className="list-group-item">Blog</li>
                                <li className="list-group-item">Support</li>
                                <li className="list-group-item">Login</li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <ul className='footerLinks'>
                                <li className="list-group-item"><span className='mainColor'>Support</span></li>
                                <li className="list-group-item">+91 1234567</li>
                                <li className="list-group-item">abc@gmail.com</li>
                                <li className="list-group-item">abc.com</li>
                            </ul>
                        </div>
                    </div>
                    <div className="text-white mt-5" style={{ fontSize: '12px' }}>
                        <p className='text-center'>Company Agency 2022 All rights reserved</p>
                    </div>
                </footer>
            </Container>
        </div >
    )
}

export default Footer