import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../../assets/images/siteLogo/siteLogo.png'
import './header.css'
import { useState } from 'react';
import ScrollHeader from '../../constants/ScrollHeader';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const [scroll, setScroll] = useState(false);
    return (
        <>
            <ScrollHeader setScroll={setScroll} />
            <Navbar expand="lg" className={`header navbar ${scroll ? 'is-fixed' : ''}`}>
                <Container>
                    <Navbar.Brand href="/"><img src={logo} alt="Naksha" width={100} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ color: 'transparent' }} >
                        <div className="custom-toggle-icon" ></div>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Nav.Link style={{ color: '#D9A56D' }} href="/#automobile">Automobile</Nav.Link>
                            <Nav.Link style={{ color: '#D9A56D' }} href="/#technology">
                                Technology
                            </Nav.Link>
                            <Nav.Link style={{ color: '#D9A56D' }} href="/#travelling">
                                Travelling
                            </Nav.Link>
                            <Nav.Link style={{ color: '#D9A56D' }} href="/#about">
                                About Us
                            </Nav.Link>
                            <NavLink style={{ color: '#D9A56D' }} className="nav-link" to="/contact-us">Contact Us</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;