import React from 'react'
import SectionOne from '../components/homeSections/SectionOne'
import SectionTwo from '../components/homeSections/SectionTwo'
import SectionThree from '../components/homeSections/SectionThree'
import SectionFoure from '../components/homeSections/SectionFoure'

const Home = () => {
    return (
        <div>
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFoure />
        </div>
    )
}

export default Home