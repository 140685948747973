import React from 'react'
import './section.css'
import { Container } from 'react-bootstrap'
const SectionThree = () => {
    return (
        <div className='bgImg3' id="travelling">
            <Container>
                <div className="headings">
                    <h1>Rev Up Your Adventure: Automobile <span className='mainColor'>for Your Journey</span></h1>
                    <p>Welcome to our website, the ultimate destination for all things automobile-related. Whether you're a car enthusiast, a road trip aficionado, or simply someone in need of reliable transportation, we've got you covered.
                    </p>
                </div>
            </Container>
        </div>
    )
}

export default SectionThree